import { ComponentProps } from "react";
import { Chip } from "components/Chip";
import { Mix_Content_Category_Enum } from "graphql/generated";

export const ContentCategoryChip = ({
  category,
  variant,
  ...props
}: ComponentProps<typeof Chip> & {
  category: Mix_ContentFragment["category"];
}) => {
  return (
    <Chip
      variant={
        variant ||
        (category === Mix_Content_Category_Enum.Offline
          ? "variant2"
          : "default")
      }
      {...props}
    >
      {category}
    </Chip>
  );
};
