import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContentGrid } from "./ContentGrid";
import { QueryHookOptions, QueryResult } from "@apollo/client";
import { MixedContentGrid } from "components/MixedContentGrid";
import { useHitBottom } from "hooks/useHitBottom";
import { LIMIT } from "utils/buildQueryVariables";

export const ContentGridQuery = <Q, V>({
  type = "default",
  variables,
  extractor,
  useQuery,
  updateQuery,
  limit = LIMIT.default,
  ...props
}: Omit<
  ComponentProps<typeof ContentGrid>,
  "contents" | "showSkeleton" | "loading"
> & {
  type?: "default" | "mixed";
  variables: V;
  extractor: (result: Q) => Mix_ContentFragment[];
  useQuery: (options: QueryHookOptions<Q, V>) => QueryResult<Q, V>;
  updateQuery?: (
    previousQueryResult: Q,
    options: {
      fetchMoreResult: Q;
      variables: {
        offset: number;
      };
    },
  ) => Q;
  limit?: number;
}) => {
  const { data, loading, fetchMore } = useQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      limit: limit,
      offset: 0,
    },
  });

  const contents = useMemo(
    () => (data ? extractor(data) : []),
    [data, extractor],
  );

  const [reachEnd, setReachEnd] = useState(false);

  const fetch = useCallback(() => {
    if (!loading && !reachEnd && updateQuery) {
      fetchMore({
        variables: {
          ...variables,
          offset: contents.length,
        },
        updateQuery,
      }).then((response) => {
        if (extractor(response.data).length === 0) {
          setReachEnd(true);
        }
      });
    }
  }, [
    contents.length,
    loading,
    variables,
    fetchMore,
    reachEnd,
    updateQuery,
    extractor,
  ]);

  useEffect(() => {
    setReachEnd(false);
  }, [variables]);

  useHitBottom({ fetch });

  const Component = {
    mixed: MixedContentGrid,
    default: ContentGrid,
  }[type];

  return (
    <Component
      contents={contents}
      showSkeleton={loading}
      loading={loading}
      {...props}
    />
  );
};
