import { ComponentProps } from "react";
import { Bookmark } from "components/inputs/Bookmark";
import { useBookmark } from "hooks/useBookmark";

export const ContentBookmark = ({
  variant,
  content,
  ...props
}: Omit<ComponentProps<typeof Bookmark>, "checked" | "onClick"> & {
  content: Mix_ContentFragment;
}) => {
  const { toggle } = useBookmark(content);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation(); // prevent bubbling when bookmark clicked
      }}
    >
      <Bookmark
        variant={variant}
        checked={!!content.bookmarked}
        onChange={toggle}
        {...props}
      />
    </div>
  );
};
