import { ComponentProps } from "react";
import { css } from "@emotion/react";
import { Text } from "components/Text";
import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";

export const ContentUpdateTime = ({
  updatetime,
  offlinetime,
  isOffline,
  ...props
}: Omit<ComponentProps<typeof Text>, "variant"> & {
  updatetime: Mix_ContentFragment["updatetime"];
} & Atomic<{
    isOffline: boolean;
    offlinetime: Mix_ContentFragment["offlinetime"];
  }>) => {
  return (
    <Text
      css={css`
        font-variant-numeric: lining-nums tabular-nums;
      `}
      variant="caption2"
      color="text.secondary"
      {...props}
    >
      {isOffline
        ? dayjs(offlinetime).format("M월 D일 HH:mm 예정")
        : formatDate(updatetime)}
    </Text>
  );
};
