import { ComponentProps } from "react";
import { Interpolation, Theme } from "@emotion/react";
import { Text } from "components/Text";

export const PageTitle = ({
  children,
  ...props
}: Omit<ComponentProps<typeof Text>, "variant"> & {
  css?: Interpolation<Theme>;
}) => {
  return (
    <Text variant="subtitle1" tag="h2" {...props}>
      {children}
    </Text>
  );
};
