import { ComponentProps } from "react";
import { css } from "@emotion/react";
import { Text } from "components/Text";

export const ContentTitle = (
  props: Omit<ComponentProps<typeof Text>, "variant">,
) => {
  return (
    <Text
      css={css`
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: keep-all;
      `}
      variant="subtitle2"
      color="text.primary"
      {...props}
    />
  );
};
