import { ComponentProps, useMemo } from "react";
import { ContentCard } from "./ContentCard";
import { ContentCardScaffold } from "./scaffolds/ContentCardScaffold";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconBox } from "components/IconBox";
import { Text } from "components/Text";
import { getDday } from "utils/dday";

const LockCircle = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  background: ${({ theme }) => theme.palette.grey[900]};
  z-index: 1;
`;

export const OriginalUpcomingCard = ({
  device,
  content,
  ...props
}: Omit<ComponentProps<typeof ContentCard>, "type">) => {
  const theme = useTheme();

  const dday = useMemo(() => {
    const _dday = getDday(content.updatetime);
    return _dday >= 0 ? `D-${_dday === 0 ? "Day" : _dday}` : "";
  }, [content.updatetime]);

  return (
    <ContentCardScaffold
      css={css`
        position: relative;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1.067; // w 320, h 300
        padding: 16px;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(15px);
        cursor: default;
      `}
      {...props}
    >
      <LockCircle>
        <IconBox size="xl" name="Lock" color="white" />
      </LockCircle>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 182px;
        `}
      >
        <Text
          css={css`
            margin-bottom: ${theme.shape.radius.medium}px;
          `}
          variant="h1"
          color="common.white.main"
        >
          {dday}
        </Text>
        <ContentCardScaffold.Title
          css={css`
            text-align: center;
            margin-bottom: 4px;
          `}
          color="common.white.main"
        >
          {content.title}
        </ContentCardScaffold.Title>
        <Text variant="body4" color="text.secondaryWhite">
          by. {content.authorname}
        </Text>
      </div>
    </ContentCardScaffold>
  );
};
