import { ClassAttributes, ComponentProps, HTMLAttributes } from "react";
import { css } from "@emotion/react";
import { PageTitle } from "components/PageTitle";
import { Text } from "components/Text";

const Section = ({
  children,
  ...props
}: ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement>) => {
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
      `}
      {...props}
    >
      {children}
    </section>
  );
};

const Header = ({
  children,
  ...props
}: ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement>) => {
  return (
    <header
      css={css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media (max-width: 768px) {
          margin-bottom: 12px;
        }
      `}
      {...props}
    >
      {children}
    </header>
  );
};

const Column = ({
  children,
  ...props
}: ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        row-gap: 4px;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

const Title = ({ children, ...props }: ComponentProps<typeof PageTitle>) => {
  return <PageTitle {...props}>{children}</PageTitle>;
};

const Desc = ({
  children,
  ...props
}: Omit<ComponentProps<typeof Text>, "variant" | "color">) => {
  return (
    <Text variant="body3" color="text.disabled" tag="p" {...props}>
      {children}
    </Text>
  );
};

const Controls = ({
  children,
  ...props
}: ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      {...props}
    >
      {children}
    </div>
  );
};

const Contents = ({
  children,
  ...props
}: ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>) => {
  return <div {...props}>{children}</div>;
};

export const SectionScaffold = Object.assign(Section, {
  Header,
  Column,
  Title,
  Desc,
  Controls,
  Contents,
});
